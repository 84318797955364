
    import Vue from 'vue'
    import dict from '@custom/dict'
    import {mapGetters} from 'vuex'
    const ChatModal = () => import('./ChatModal.vue')
    export default Vue.extend({
        name: 'CopyRight',
        components: {
            ChatModal
        },
        data() {
            return {
                dict
            }
        },
        computed: {
            ...mapGetters([
                'userInfo',
                'comConfig'
            ])
        },
        methods:{
            openChat(){
                let chatModal:any = this.$refs.chatModal
                chatModal.showModal()
            }
        }
    })
